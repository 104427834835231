@import url(https://fonts.googleapis.com/css?family=Nunito:200);
* {
  box-sizing: border-box;
}

html {
  padding-left: 10px;
  padding-right: 10px;
}

body {
  padding: 0;
  background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(204,204,204,1) 100%);
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
  width: 100%;
  margin: 0;
}

@media (min-width: 870px) {
  body {
    width: 850px;
    margin: auto;
  }
}

h1 {
  color: #7d7d7d;
  letter-spacing: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 2em;
  text-transform: none;
  font-weight: bold;
}

a {
  color: white;
  -webkit-text-decoration: underline solid transparent;
          text-decoration: underline solid transparent;
  transition: -webkit-text-decoration 0.35s ease;
  transition: text-decoration 0.35s ease;
  transition: text-decoration 0.35s ease, -webkit-text-decoration 0.35s ease;
}

a:hover {
  -webkit-text-decoration: underline solid white;
          text-decoration: underline solid white;
}

p {
  margin: 0;
  color: #7d7d7d;
  font-size: 17px;
  margin-bottom: 10px;
}

.page-container {
  padding: 10px;
}

.left {
  float: left;
  margin-right: 20px;
}

.right {
  float: right;
  margin-left: 20px;
}

.achieve-image {
  border: 4px solid #7d7d7d;
  border-radius: 5px;
}

.full-width-image {
  width: 100%;
}

.youtube-container {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main p{
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.main {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.showcase {
	display: flex;

	align-items: center;
	justify-content: center;

	margin-top: 30px;
	margin-bottom: 30px;
}

.showcase--youtube {
	width: 100%;
}

.showcase--text {
	margin-left: 30px;
	margin-right: 30px;
}

.showcase--title {
	font-size: 1.5em;
}

.showcase--thumbnail-grid {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 20px;
	margin-bottom: 30px;
}

.showcase--thumbnail-grid img {
	width: 100%;
	box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5)
}

.showcase--description {
	max-width: 600px;
}

.showcase--thumbnail {
	transition: all 0.2s;
	position: relative;
	bottom: 0px;
	cursor: pointer;
}

.showcase--thumbnail:hover {
	bottom: 5px;
}

.showcase--modal-close {
	position: absolute;
	top: 0px;
	right: -30px;
	cursor: pointer;
	font-size: 25px;
	color: black;
	font-weight: bold;
}

@media (max-width: 768px) {
	.showcase--youtube {
		order: 2;
	}
	.showcase {
		flex-direction: column;
		margin-top: 0px;
	}
	.showcase--text {
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 10px;
	}
}
nav {
  background-color: #7d7d7d;
  color: white;
  padding: 10px;
}

nav a {
  margin-right: 20px;
}


@media only screen and (max-width: 600px) {
	.nav--home-link {
		display: none;
	}
}

.header--title {
	margin-left: 10px;
}
footer {
  background-color: #7d7d7d;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  clear: both;
  height: 6rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-jacob {
  flex-grow: 1;
}

.footer-globe {
  text-align: right;
  align-self: flex-end;
}

.footer-image {
  width: 40px;
  margin: 5px;
}

.footer-link {
  padding-left: 5px;
  color: white;
}

.app-container {
	position: relative;
  	min-height: 100vh;
  	display: flex;
  	flex-direction: column;
}

.page-container {
	padding-bottom: 6rem;
	flex-grow: 1;
	display: flex;
}
