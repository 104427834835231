nav {
  background-color: #7d7d7d;
  color: white;
  padding: 10px;
}

nav a {
  margin-right: 20px;
}


@media only screen and (max-width: 600px) {
	.nav--home-link {
		display: none;
	}
}

.header--title {
	margin-left: 10px;
}