.youtube-container {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
