footer {
  background-color: #7d7d7d;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  clear: both;
  height: 6rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-jacob {
  flex-grow: 1;
}

.footer-globe {
  text-align: right;
  align-self: flex-end;
}

.footer-image {
  width: 40px;
  margin: 5px;
}

.footer-link {
  padding-left: 5px;
  color: white;
}
