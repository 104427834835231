.showcase {
	display: flex;

	align-items: center;
	justify-content: center;

	margin-top: 30px;
	margin-bottom: 30px;
}

.showcase--youtube {
	width: 100%;
}

.showcase--text {
	margin-left: 30px;
	margin-right: 30px;
}

.showcase--title {
	font-size: 1.5em;
}

.showcase--thumbnail-grid {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 20px;
	margin-bottom: 30px;
}

.showcase--thumbnail-grid img {
	width: 100%;
	box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5)
}

.showcase--description {
	max-width: 600px;
}

.showcase--thumbnail {
	transition: all 0.2s;
	position: relative;
	bottom: 0px;
	cursor: pointer;
}

.showcase--thumbnail:hover {
	bottom: 5px;
}

.showcase--modal-close {
	position: absolute;
	top: 0px;
	right: -30px;
	cursor: pointer;
	font-size: 25px;
	color: black;
	font-weight: bold;
}

@media (max-width: 768px) {
	.showcase--youtube {
		order: 2;
	}
	.showcase {
		flex-direction: column;
		margin-top: 0px;
	}
	.showcase--text {
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 10px;
	}
}