@import url('https://fonts.googleapis.com/css?family=Nunito:200');

* {
  box-sizing: border-box;
}

html {
  padding-left: 10px;
  padding-right: 10px;
}

body {
  padding: 0;
  background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(204,204,204,1) 100%);
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
  width: 100%;
  margin: 0;
}

@media (min-width: 870px) {
  body {
    width: 850px;
    margin: auto;
  }
}

h1 {
  color: #7d7d7d;
  letter-spacing: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 2em;
  text-transform: none;
  font-weight: bold;
}

a {
  color: white;
  text-decoration: underline solid transparent;
  transition: text-decoration 0.35s ease;
}

a:hover {
  text-decoration: underline solid white;
}

p {
  margin: 0;
  color: #7d7d7d;
  font-size: 17px;
  margin-bottom: 10px;
}

.page-container {
  padding: 10px;
}

.left {
  float: left;
  margin-right: 20px;
}

.right {
  float: right;
  margin-left: 20px;
}

.achieve-image {
  border: 4px solid #7d7d7d;
  border-radius: 5px;
}

.full-width-image {
  width: 100%;
}
