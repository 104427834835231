.app-container {
	position: relative;
  	min-height: 100vh;
  	display: flex;
  	flex-direction: column;
}

.page-container {
	padding-bottom: 6rem;
	flex-grow: 1;
	display: flex;
}