.main p{
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.main {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}